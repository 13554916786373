const image = document.querySelectorAll('.gallery__item')

image.forEach((item) => {
    let nextSibling = item.nextElementSibling;
    item.addEventListener('click', () => {
        nextSibling.classList.add('open')
    })
    const close = nextSibling.querySelector('.gallery__lightbox-close')
    close.addEventListener('click', () => {
        // console.log(close)
        nextSibling.classList.remove('open')
    })
})