let navToggle = false

const navOpen = document.querySelector('.nav__toggle')
const navClose = document.querySelector('.nav__close-inner')
const navLayer = document.querySelector('.nav__layer')

navOpen.addEventListener('click', toggleNav)
navClose.addEventListener('click', toggleNav)


function toggleNav() {
    navToggle = !navToggle
    
    if (navToggle) {
        navLayer.classList.add('open')
    } else {
        navLayer.classList.remove('open')
    }
}
