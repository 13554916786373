const animate = document.querySelectorAll('.animate');

observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.intersectionRatio > 0) {
      entry.target.classList.add('animate-start');
    } else {
    }
  });
});

animate.forEach(item => {
  observer.observe(item);
});