import { CountUp } from 'countup.js';

const facts = document.querySelectorAll('.facts__item');

const countOptions = {
    duration: 4,
    separator: '',
    decimal: ',',
}

observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
    if (entry.intersectionRatio > 0) {
        const number = entry.target.querySelector('.facts__number')
        const numberData = number.dataset.number
        var countUp = new CountUp(number, numberData, countOptions);
        countUp.start();
    } else {
    }
});
});

facts.forEach(item => {
    observer.observe(item);
});