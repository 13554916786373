import Swiper from 'swiper';
// import Swiper styles
import 'swiper/swiper-bundle.css';


const swiper = new Swiper('.imageslider__container', {
    // Optional parameters
    loop: true,
  
    // // If we need pagination
    // pagination: {
    //   el: '.swiper-pagination',
    // },
  
    // // Navigation arrows
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
  
    // // And if we need scrollbar
    // scrollbar: {
    //   el: '.swiper-scrollbar',
    // },
  });