let dateItem = document.querySelectorAll('.js-date');

if(dateItem.length > 0) {
    const firstDateName = dateItem[0].querySelector('.date__item-name')
    firstDateName.classList.add('active')
    
    const firstDateText = dateItem[0].querySelector('.date__text')
    firstDateText.classList.add('active')
}

dateItem.forEach((item) => {
    item.addEventListener('click', () => {
        const dateText = item.querySelector('.date__text');
        const dateName = item.querySelector('.date__item-name');
        dateName.classList.toggle('active')
        dateText.classList.toggle('active')
    })
})